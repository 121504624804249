'use client';

import ServiceCard from "@/app/components/service-card";
import React from "react";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default function SectionServices() {
    const sectionRef = React.useRef<HTMLDivElement>(null);

    useGSAP((context, contextSafe) => {
            if (!sectionRef.current) return;

            gsap.registerPlugin(ScrollTrigger);
            ScrollTrigger.normalizeScroll(true);

            const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-primary').trim();
            const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary').trim();
            const accentColor = getComputedStyle(document.documentElement).getPropertyValue('--color-accent').trim();

            let mm = gsap.matchMedia();

            mm.add({
                isDesktop: '(min-width: 768px)',
                isMobile: '(max-width: 767px)',
            }, (context) => {
                let {isDesktop, isMobile} = context.conditions!;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: sectionRef.current,
                        pin: true,
                        start: 'top 13%',
                        end: isMobile ? '260% 20%' : '350% 20%',
                        scrub: true,
                        markers: false,
                    },
                })
                    .to(':root', {
                        ease: 'none',
                        "--background-rgb": secondaryColor,
                        "--foreground-rgb": "0 0 0",
                        duration: 0.5,
                    }, 0)
                    .to(':root', {
                        ease: 'none',
                        "--foreground-rgb": '0 0 0',
                        "--background-rgb": '255 255 255',
                        duration: 2,
                        delay: 6
                    }, 1)
                    .to('.navbar', {
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        duration: 0.5,
                        delay: 6
                    }, 1)

                const cards = gsap.utils.toArray('.service-card') as HTMLElement[];

                cards.forEach((card, index) => {
                    tl.fromTo(card as any, {
                        maxWidth: 0,
                    }, {
                        maxWidth: '100%',
                        duration: 1,
                        delay: index * 1,
                        ease: 'power3',
                    }, 0);
                    if (index < 4 || isMobile) {
                        tl.fromTo(card,
                            {
                                height: isMobile ? '220px' : '440px',
                            }, {
                                height: isMobile ? '100px' : '150px',
                                duration: 0.25,
                                delay: 4,
                                ease: 'power3',
                            }, 0);
                    }
                })
            });
        }, {
            dependencies: [sectionRef]
        }
    );

    return (
        <div className={'w-full mt-8'} ref={sectionRef}>
            <h2 className={'text-2xl md:text-4xl font-medium'}>Pomůžeme vám s tím!</h2>
            <p className={'mt-4'}>Jsme zaměřeni na tvorbu obsahu a jeho strategickou distribuci.</p>
            <div className={'w-full grid grid-cols-2 md:grid-cols-4 grid-rows-1 gap-4 mt-8'}>
                <ServiceCard image={'/images/photographer.jpg'} text={'Fotky'} className={''} imageClassName={'brightness-75'}/>
                <ServiceCard image={'/images/video.jpg'} text={'Videa'} className={''} imageClassName={'brightness-75'}/>
                <ServiceCard image={'/images/web.jpg'} text={'Web'} className={''} imageClassName={'brightness-75'}/>
                <ServiceCard image={'/images/design.jpg'} text={'Design'} className={'max-w-0'} imageClassName={'brightness-75'}/>
            </div>
            <div className={'w-full grid grid-cols-2 md:grid-cols-4 grid-rows-1 gap-4 mt-8'}>
                <ServiceCard small={true} image={'/images/reels.jpg'} text={'Reels'} className={'max-w-0'} imageClassName={'brightness-75'}/>
                <ServiceCard small={true} image={'/images/socialmedia.jpg'} text={'Sociální sítě'} className={'max-w-0'} imageClassName={'brightness-75'}/>
                <ServiceCard small={true} image={'/images/emailing.jpg'} text={'E-mailing'} className={'max-w-0'} imageClassName={'brightness-75'}/>
                <ServiceCard small={true} image={'/images/advertising.jpg'} text={'Reklama'} className={'max-w-0'} imageClassName={'brightness-75'}/>
            </div>
        </div>
    );
}
