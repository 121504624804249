'use client';

import React from "react";
import ContactCard from "@/app/components/contact-card";
import {BiMailSend, BiPhone} from "react-icons/bi";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";

export default function SectionContact() {
    const sectionRef = React.useRef<HTMLDivElement>(null);

    useGSAP(() => {
        gsap.timeline({
            scrollTrigger: {
                trigger: sectionRef.current,
                start: "40% bottom",
                end: "-50% bottom",
                markers: false,
                scrub: false,
                toggleActions: "play play reverse reverse",
            }
        }).fromTo('.contact-card:nth-child(1)', {
            x: '-500px',
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'power4.in',
        }, 0).fromTo('.contact-card:nth-child(2)', {
            x: '500px',
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'power4.in',
        }, 0);
    }, []);

    return (
        <div className={'w-full mt-8 overflow-x-hidden pb-4'} ref={sectionRef}>
            <h2 className={'text-2xl md:text-4xl font-medium'}>Spojte se s námi</h2>
            <p className={'mt-4'}>Pokud máte jakékoli dotazy, nebo už víte, že to prostě chcete, neváhejte nás kontaktovat!</p>
            <div className={'flex gap-4 mt-8 items-center flex-col md:flex-row'}>
                <ContactCard value={'info@boldmove.cz'} icon={<BiMailSend size={'1.6rem'}/>} className={'bg-primary text-white'}/>
                <ContactCard value={'+420 773 202 932'} icon={<BiPhone size={'1.6rem'}/>} className={'bg-primary text-white'}/>
            </div>
        </div>
    );
}
