'use client';

import SplitType from "split-type";
import gsap from "gsap";
import {useEffect, useState} from "react";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import "gsap";

export default function GlobalAnimator() {
    const [revealTypes, setRevealTypes] = useState<HTMLElement[]>([]);

    useEffect(() => {
        setRevealTypes(Array.from(document.querySelectorAll('.reveal-type')));
    }, []);

    useGSAP((context, contextSafe) => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.normalizeScroll(true);

        revealTypes.forEach((revealType, i) => {
            const splitText = new SplitType(revealType as any, {types: 'chars,words'});

            gsap.from(splitText.chars, {
                scrollTrigger: {
                    trigger: revealType,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: false,
                    toggleActions: 'play play reverse reverse'
                },
                y: -100,
                scaleY: 0,
                // transformOrigin: 'top',
                opacity: 0,
                duration: 0.3,
                stagger: 0.05,
            });
        });
    }, [revealTypes]);

    return <></>;
}
