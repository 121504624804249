import {HTMLAttributes} from "react";
import Image from "next/image";
import {Card} from "@nextui-org/react";

interface Props extends HTMLAttributes<HTMLDivElement> {
    image: string;
    text: string;
    imageClassName?: string;
    small?: boolean;
}

export default function ServiceCard({image, text, className, imageClassName, small = false}: Props) {
    return (
        <Card shadow={'none'} className={`service-card ${small ? 'h-[150px]' : 'h-[220px] md:h-[440px]'} w-full flex-1 rounded-3xl transition shadow-md hover:shadow-none hover:scale-[104%] ${className}`}>
            <div className="absolute z-10 top-1 flex-col !items-start px-4 md:px-8 py-2 md:py-6">
                <h3 className="text-white font-bold text-2xl md:text-4xl">{text}</h3>
            </div>
            <Image
                width={300}
                height={400}
                alt={text}
                className={`z-0 w-full h-full object-cover ${imageClassName}`}
                src={image}
            />
        </Card>
    );
}
