import React from "react";
import Image from "next/image";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    imageOnTop: boolean;
    image: string;
    alt: string;
    title: string;
}

export default function SmallInfoCard({className, imageOnTop, image, alt, title, children}: Props) {
    return <div className={`p-4 md:p-6 2xl:p-10 rounded-3xl flex-1 transition hover:shadow-xl why-video-on-web-card ${className} flex ${imageOnTop ? 'flex-col-reverse' : 'flex-col'} gap-4`}>
        <div>
            <h3 className="text-xl md:text-2xl text-white">{title}</h3>
            <div className={'mt-3 text-gray-100 content'}>{children}</div>
        </div>
        <div className={'rounded-2xl py-4 px-10 h-32 bg-gray-50'}>
            <Image src={image} alt={alt} width={400} height={100} className={'h-full mx-auto'}/>
        </div>
    </div>
}
