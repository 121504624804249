'use client';

import Image from "next/image";
import Link from "next/link";
import React from "react";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";

export default function Footer() {
    const footerRef = React.useRef<HTMLDivElement>(null);

    useGSAP(() => {
        if (!footerRef.current) return;
        let mm = gsap.matchMedia();

        mm.add({
            isDesktop: '(min-width: 768px)',
            isMobile: '(max-width: 767px)',
        }, (context) => {
            let {isDesktop, isMobile} = context.conditions!;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: footerRef.current,
                    start: isMobile ? 'top bottom' : '300px bottom',
                    end: 'bottom 20%',
                    scrub: false,
                    toggleActions: 'play play reverse reverse',
                    markers: false,
                }
            });

            if (isMobile) {
                tl.fromTo(footerRef.current, {
                    scale: 0,
                }, {
                    scale: 1,
                    duration: 0.3,
                    ease: 'power4.in',
                })
            } else {
                tl.fromTo(footerRef.current, {
                    y: -300,
                }, {
                    y: 0,
                    duration: 0.3,
                    ease: 'power4.in',
                });
            }
        });
    });

    return (
        <div className={'w-full overflow-hidden'}>
            <footer className={'mt-16 w-full bg-secondary min-h-32 gap-8 rounded-3xl text-black p-8 md:p-16 flex flex-col md:flex-row justify-between items-center md:items-start'} ref={footerRef}>
                <div className={'flex flex-col justify-start items-start gap-4'}>
                    <Image src={'/logo.svg'} alt={'logo'} className={'max-h-full mx-auto md:mx-0'} width={100} height={100}/>
                    <div className={'flex flex-col gap-1'}>
                        <div>Kontes Digital s.r.o.</div>
                        <div>Sídlo: tř Kpt. Jaroše 1922/3, 602 00 Brno</div>
                        <div>IČO: 10663282</div>
                    </div>
                </div>
                <div className={'flex flex-col md:flex-row gap-4 md:gap-16 items-center md:items-start justify-center md:justify-end footer-links'}>
                    <div className={'flex flex-col gap-2 md:items-start'}>
                        <div className={'font-bold'}>Odkazy</div>
                        <div className={'flex flex-col gap-1'}>
                            <Link href={'/#videa-na-webu'}>Videa na webu</Link>
                            <Link href={'/#sluzby'}>Služby</Link>
                            <Link href={'/#kontakt'}>Kontakt</Link>
                        </div>
                    </div>
                    <div className={'flex flex-col gap-2 md:items-start'}>
                        <div className={'font-bold'}>Další produkty</div>
                        <div className={'flex flex-col gap-1'}>
                            <Link href={'https://gastrostart.cz'}>GastroSTART</Link>
                            <Link href={'https://gastrostart.cz/restomat'}>Restomat</Link>
                        </div>
                    </div>
                </div>

            </footer>
        </div>
    )
}
