'use client';

import {ReactNode, useRef} from "react";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";

export default function TextCarousel({texts, id}: { texts: ReactNode[], id: string }) {
    const stripeRef = useRef<HTMLDivElement>(null);

    // useGSAP(() => {
    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: `#${id}`,
    //             start: 'top bottom',
    //             end: 'bottom top',
    //             scrub: 1,
    //             markers: false,
    //         }
    //     })
    //         .to(stripeRef.current, {
    //             transform: `translateX(${-stripeRef.current!.clientWidth / 2}px)`,
    //             duration: 1,
    //             ease: 'power1.inOut',
    //         })
    //     });

    return (
        <div className={'w-full h-full bg-gray-900 rounded-2xl'}>
            <div className={`overflow-hidden relative h-full w-full rounded-2xl`}>
                <div className={`flex absolute left-0 justify-center items-center h-full animate-carousel-loop`} id={id} ref={stripeRef}>
                    {texts.map((text, id) => {
                        return (
                            <div key={`cl-0-${id}`} className={'w-64 h-full flex items-center justify-center'}>{text}</div>
                        );
                    })}
                    {texts.map((text, id) => {
                        return (
                            <div key={`cl-1-${id}`} className={'w-64 h-full flex items-center justify-center'}>{text}</div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
