'use client';

import React from "react";
import Image from "next/image";
import {useGSAP} from "@gsap/react";
import gsap from "gsap";
import SmallInfoCard from "@/app/components/small-info-card";
import {ScrollTrigger} from "gsap/ScrollTrigger";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
}

export default function SectionVideoOnWeb({className}: Props) {
    const sectionRef = React.useRef<HTMLDivElement>(null);
    const cardContainerRef = React.useRef<HTMLDivElement>(null);

    useGSAP((context, contextSafe) => {
            if (!sectionRef.current) return;

            let mm = gsap.matchMedia();

            const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--color-primary').trim();

            gsap.registerPlugin(ScrollTrigger);
            ScrollTrigger.normalizeScroll(true);

            mm.add({
                isDesktop: '(min-width: 768px)',
                isMobile: '(max-width: 767px)',
            }, (context) => {
                const {isDesktop, isMobile} = context.conditions!;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: sectionRef.current,
                        pin: true,
                        start: 'top 13%',
                        end: '550% 20%',
                        scrub: true,
                        markers: false,
                    },
                })
                    .to(':root', {
                        ease: 'none',
                        "--background-rgb": `${primaryColor}`,
                        "--foreground-rgb": "255 255 255",
                        duration: 0.5,
                    }, 0)
                    .to('.why-video-on-web-card', {
                        backgroundColor: '#fff',
                        border: '2px solid black',
                        color: '#000',
                        duration: 0.5,
                    }, 0)
                    .to('.why-video-on-web-card h3', {
                        color: '#000',
                        duration: 0.5,
                    }, 0)
                    .to('.why-video-on-web-card .content', {
                        color: '#3f3f3f',
                        duration: 0.5,
                    }, 0)
                    .to('.navbar', {
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        duration: 0.5,
                    }, 0);

                const cards = gsap.utils.toArray('.why-video-on-web-card') as HTMLElement[];

                cards.forEach((card, index) => {
                    tl.fromTo(card as any, {
                        x: isMobile ? 0 : index * 40,
                        y: isMobile ? index * 20 : 0,
                    }, {
                        x: index < (cards.length - 1) ? -1000 : (isMobile ? 0 : index * 40),
                        y: index < (cards.length - 1) ? 0 : (isMobile ? index * 20 : 0),
                        rotation: index < (cards.length - 1) ? index % 2 === 0 ? 10 : -10 : 0,
                        opacity: index < (cards.length - 1) ? 0 : 1,
                        delay: index,
                        ease: 'elastic',
                    }, 1);
                    if (index > 0) {
                        tl.to(cardContainerRef.current, {
                            x: isMobile ? 0 : -index * 40,
                            y: isMobile ? -index * 20 : 0,
                            delay: index - 0.3,
                        }, 1);
                    }
                });
            });
        }
        ,
        {
            dependencies: [sectionRef]
        }
    );

    return <div className={`${className}`} ref={sectionRef}>
        <h2 className={'text-2xl md:text-4xl font-medium'}>Proč video na webu?</h2>
        <p className={'mt-4'}>Co mají společného firmy Rolex, McDonald&apos;s, Kia a další?<br/><b>Umisťují videa na web.</b></p>
        <div className={'md:w-1/2 md:mx-auto grid grid-cols-1 grid-rows-1 gap-4 mt-8 lg:mt-16'} ref={cardContainerRef}>
            <SmallInfoCard imageOnTop={false} image={'/illustrations/web-performance.svg'} alt={'růst webu'} title={'Zvyšují návštěvnost webu'}
                           className={'bg-primary col-start-1 col-end-1 row-start-1 row-end-1 z-[31]'}>
                <p><b>86%</b> dotázaných marketérů uvádí, že videa významně zvýšila návštěvnost webu.</p>
            </SmallInfoCard>
            <SmallInfoCard imageOnTop={false} image={'/illustrations/purchase.svg'} alt={'nákup'} title={'Zvyšují pravděpodobnost nákupu'}
                           className={'bg-gray-200 col-start-1 col-end-1 row-start-1 row-end-1 z-[30]'}>
                <p>Zákazníci, kteří zhlédli celé video, nakoupí o <b>85%</b> pravděpodobněji.</p>
            </SmallInfoCard>

            <SmallInfoCard imageOnTop={false} image={'/illustrations/purchase.svg'} alt={'nákup'} title={'Zvyšují důvěru'}
                           className={'bg-secondary col-start-1 col-end-1 row-start-1 row-end-1 z-[29]'}>
                <p><b>87%</b> spotřebitelů uvádí, že video obsah zvyšuje jejich důvěru značce.</p>
            </SmallInfoCard>
            <SmallInfoCard imageOnTop={false} image={'/illustrations/purchase.svg'} alt={'nákup'} title={'Zvyšují prodeje'}
                           className={'bg-gray-200 col-start-1 col-end-1 row-start-1 row-end-1 z-[28]'}>
                <p>V <b>81%</b> případů video obsah zvýšil prodeje.</p>
            </SmallInfoCard>
            <SmallInfoCard imageOnTop={false} image={'/illustrations/purchase.svg'} alt={'nákup'} title={'Zaujímají zákazníky'}
                           className={'bg-accent col-start-1 col-end-1 row-start-1 row-end-1 z-[27]'}>
                <p>Videa mají na sociálních sítích v průměru <b>3x</b> více reakcí než fotky.</p>
            </SmallInfoCard>
            <SmallInfoCard imageOnTop={false} image={'/illustrations/purchase.svg'} alt={'nákup'} title={'Mají velký dosah'}
                           className={'bg-gray-200 col-start-1 col-end-1 row-start-1 row-end-1 z-[26]'}>
                <p>Video-obsah má na sociálních sítích v průměru <b>2x</b> větší dosah než fotky.</p>
            </SmallInfoCard>
        </div>
    </div>;
}
