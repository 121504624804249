import {ReactNode} from "react";

export default function ContactCard({value, icon, className = ""}: { value: string, icon: ReactNode, className?: string }) {
    return <a href={value.includes('@') ? `mailto:${value}` : `tel:${value}`}
        className={`w-full contact-card flex-1 flex items-center justify-start gap-4 px-8 py-6 font-medium text-2xl rounded-2xl z-40 shadow-md hover:cursor-pointer hover:shadow-none transition ${className}`}>
        <div className={'bg-white flex justify-center items-center rounded-lg p-2 text-black'}>
            {icon}
        </div>
        <span>{value}</span>
    </a>
}
